import { HttpErrorResponse } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild, Inject, inject, TemplateRef, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { AppConfirmService } from "app/shared/services/app-confirm/app-confirm.service";
// import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { CommonService } from "app/shared/services/MyServices/common.service";
import { DataPassService } from "app/shared/services/MyServices/data-pass.service";
import { InventoryService } from "app/shared/services/MyServices/inventory.service";
import Swal from "sweetalert2";

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DatePipe } from '@angular/common';
import { AddProductComponent } from "../add-product/add-product.component";
import { DealerInfoComponent } from "../dealer-info/dealer-info.component";
import { ApproveData } from "app/shared/models/models";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import {MatButtonModule} from '@angular/material/button';

@Component({
  selector: 'app-tsm-product-list',
  templateUrl: './tsm-product-list.component.html',
  styleUrls: ['./tsm-product-list.component.scss']
})
export class TsmProductListComponent implements OnInit, OnDestroy {
  @ViewChild('dialogContent', { static: false }) dialogContent!: TemplateRef<any>;
  @ViewChild("scrollBottom", { static: false })
  private scrollBottom: ElementRef;
  checkTML: any;
  DivisionId: string;
  public viewMode: string = "list-view";
  pgTitle = "Confirmation ";
  pgText = "Are you sure you want to change the selection?";
  ServiceData: [];
  visiblitydata = "2";
  ProductGroupLine = [];
  Type = [];
  config: any;
  StockVehicleInfo: any = [];
  InventoryImages: any = [];
  InventoryCoverImage: [];
  FinaldataData = [];
  temp = [];
  data: any;
  chassis_number = [];
  showmsg: boolean = false;

  p: number = 1;
  readable: any;
  regionalData: any = [];
  previousIndex: any = null;
  PANIndiaData: any;
  isApiCalled: boolean;
  totalCount: any = 0;
  selectedTabIndex: any = 0;
  isApiCalledMyStock: boolean = true;
  isApiCalledRegion: boolean;
  isApiCalledPan: boolean;
  SessionData: any;
  confirmForm: FormGroup;
  params_approval:any='INITIATED'
  isMobile: boolean=false;
  jsonData=[
 {
  "Make": "TATA",
  "PL": "LPT 4825",
  "PPL": "MAV 48",
  "Rajasthan": 2200000,
  "Maharashtra": 2300000,
  "Gujarat": 2475000,
  "Karnataka": 2300000,
  "Jharkhand": 2250000,
  "West Bengal": 2450000,
  "Uttar Pradesh": 2250000,
  "Delhi": 2275000,
  "Haryana": 1500000,
  "APTS": 2400000,
  "Madhya Pradesh": 2650000,
  "Chattisgarh": 2700000,
  "Tamil Nadu": 2500000,
  "Orissa": 3100000,
  "Bihar": 2000000,
  "Punjab": 2200000,
  "HP": "NA",
  "J&K": "NA",
  "Kerala": 2000000,
  "Min": 1500000,
  "Max": 3100000,
  "Manufacturer_Year": "2017-2019"
 },
 {
  "Make": "TATA",
  "PL": "SIGNA 4825.TK. FBV",
  "PPL": "MAV Tippers 48",
  "Rajasthan": 2250000,
  "Maharashtra": 2350000,
  "Gujarat": 2450000,
  "Karnataka": 2450000,
  "Jharkhand": 2200000,
  "West Bengal": 2300000,
  "Uttar Pradesh": 2300000,
  "Delhi": 2350000,
  "Haryana": 1550000,
  "APTS": 2550000,
  "Madhya Pradesh": 2240000,
  "Chattisgarh": 2300000,
  "Tamil Nadu": 2600000,
  "Orissa": 3300000,
  "Bihar": 1900000,
  "Punjab": 2500000,
  "HP": 2100000,
  "J&K": 2120000,
  "Kerala": 2100000,
  "Min": 1550000,
  "Max": 3300000,
  "Manufacturer_Year": "2017-2019"
 },
 {
  "Make": "TATA",
  "PL": "LPS5530TC",
  "PPL": "Tractor Trailer 55",
  "Rajasthan": 1700000,
  "Maharashtra": 2200000,
  "Gujarat": 2300000,
  "Karnataka": 2700000,
  "Jharkhand": 1800000,
  "West Bengal": 1800000,
  "Uttar Pradesh": 1700000,
  "Delhi": 1725000,
  "Haryana": 1350000,
  "APTS": 2800000,
  "Madhya Pradesh": 1600000,
  "Chattisgarh": 1900000,
  "Tamil Nadu": 1450000,
  "Orissa": 1760000,
  "Bihar": 1700000,
  "Punjab": 2100000,
  "HP": "NA",
  "J&K": "NA",
  "Kerala": 1300000,
  "Min": 1350000,
  "Max": 2800000,
  "Manufacturer_Year": "2017-2019"
 },
 {
  "Make": "TATA",
  "PL": "LPS5525TC 4x2",
  "PPL": "Tractor Trailer 52\/55",
  "Rajasthan": 1700000,
  "Maharashtra": 1950000,
  "Gujarat": 2025000,
  "Karnataka": 1100000,
  "Jharkhand": 1900000,
  "West Bengal": 1850000,
  "Uttar Pradesh": 1700000,
  "Delhi": 1700000,
  "Haryana": 1350000,
  "APTS": 1200000,
  "Madhya Pradesh": 1600000,
  "Chattisgarh": 1700000,
  "Tamil Nadu": 1300000,
  "Orissa": 1600000,
  "Bihar": 1600000,
  "Punjab": 2000000,
  "HP": "NA",
  "J&K": "NA",
  "Kerala": 1300000,
  "Min": 1100000,
  "Max": 2025000,
  "Manufacturer_Year": "2017-2019"
 },
 {
  "Make": "TATA",
  "PL": "SIGNA 2823.K. FBV",
  "PPL": "MAV Tippers 28",
  "Rajasthan": 1800000,
  "Maharashtra": 1820000,
  "Gujarat": 1900000,
  "Karnataka": 1400000,
  "Jharkhand": 2000000,
  "West Bengal": 1800000,
  "Uttar Pradesh": 1900000,
  "Delhi": 1875000,
  "Haryana": 1300000,
  "APTS": 1600000,
  "Madhya Pradesh": 1800000,
  "Chattisgarh": 1750000,
  "Tamil Nadu": 1500000,
  "Orissa": 1900000,
  "Bihar": 1300000,
  "Punjab": 1800000,
  "HP": 1750000,
  "J&K": 1770000,
  "Kerala": 1600000,
  "Min": 1300000,
  "Max": 2000000,
  "Manufacturer_Year": "2017-2019"
 },
 {
  "Make": "TATA",
  "PL": "LPT 4225",
  "PPL": "MAV 42",
  "Rajasthan": 1850000,
  "Maharashtra": 2425000,
  "Gujarat": 2500000,
  "Karnataka": 2200000,
  "Jharkhand": 2600000,
  "West Bengal": 2250000,
  "Uttar Pradesh": 2000000,
  "Delhi": 2100000,
  "Haryana": 1500000,
  "APTS": 2300000,
  "Madhya Pradesh": 1800000,
  "Chattisgarh": 1750000,
  "Tamil Nadu": 2400000,
  "Orissa": 2350000,
  "Bihar": 1800000,
  "Punjab": 2100000,
  "HP": 1750000,
  "J&K": 1770000,
  "Min": 1500000,
  "Max": 2600000,
  "Manufacturer_Year": "2017-2019"
 },
 {
  "Make": "TATA",
  "PL": "SIGNA 3525.TK. FBV",
  "PPL": "MAV Tippers 35",
  "Rajasthan": 2100000,
  "Maharashtra": 1930000,
  "Gujarat": 2000000,
  "Karnataka": 1500000,
  "Jharkhand": 2200000,
  "West Bengal": 2100000,
  "Uttar Pradesh": 2100000,
  "Delhi": 2250000,
  "Haryana": 1350000,
  "APTS": 1600000,
  "Madhya Pradesh": 2000000,
  "Chattisgarh": 2100000,
  "Tamil Nadu": 1700000,
  "Orissa": 2100000,
  "Bihar": 1700000,
  "Punjab": 2050000,
  "HP": 1700000,
  "J&K": 1700000,
  "Min": 1350000,
  "Max": 2250000,
  "Manufacturer_Year": "2017-2019"
 },
 {
  "Make": "TATA",
  "PL": "SIGNA 2825.K. FBV",
  "PPL": "MAV Tippers 28",
  "Rajasthan": 1750000,
  "Maharashtra": 2180000,
  "Gujarat": 2200000,
  "Karnataka": 1400000,
  "Jharkhand": 2000000,
  "West Bengal": 1850000,
  "Uttar Pradesh": 1900000,
  "Delhi": 1975000,
  "Haryana": 1250000,
  "APTS": 1500000,
  "Madhya Pradesh": 1800000,
  "Chattisgarh": 1850000,
  "Tamil Nadu": 1600000,
  "Orissa": 1950000,
  "Bihar": 1300000,
  "Punjab": 1900000,
  "HP": 1650000,
  "J&K": 1600000,
  "Kerala": 1400000,
  "Min": 1250000,
  "Max": 2200000,
  "Manufacturer_Year": "2017-2019"
 },
 {
  "Make": "TATA",
  "PL": "LPT 2818",
  "PPL": "MAV 28",
  "Rajasthan": 1400000,
  "Maharashtra": 1550000,
  "Gujarat": 1800000,
  "Karnataka": 1200000,
  "Jharkhand": 1500000,
  "West Bengal": 1450000,
  "Uttar Pradesh": 1400000,
  "Delhi": 1600000,
  "Haryana": 1250000,
  "APTS": 1200000,
  "Madhya Pradesh": 1500000,
  "Chattisgarh": 1600000,
  "Tamil Nadu": 1200000,
  "Orissa": 1300000,
  "Bihar": 1300000,
  "Punjab": 1500000,
  "HP": 1600000,
  "J&K": 1630000,
  "Min": 1200000,
  "Max": 1800000,
  "Manufacturer_Year": "2017-2019"
 },
 {
  "Make": "TATA",
  "PL": "LPT 3518",
  "PPL": "MAV 35",
  "Rajasthan": 1850000,
  "Maharashtra": 2150000,
  "Gujarat": 2200000,
  "Karnataka": 1500000,
  "Jharkhand": 2000000,
  "West Bengal": 1800000,
  "Uttar Pradesh": 2050000,
  "Delhi": 2100000,
  "Haryana": 1100000,
  "APTS": 1800000,
  "Madhya Pradesh": 1600000,
  "Chattisgarh": 2050000,
  "Tamil Nadu": 1500000,
  "Orissa": 1900000,
  "Bihar": 1500000,
  "Punjab": 1800000,
  "HP": 1700000,
  "J&K": 1710000,
  "Min": 1100000,
  "Max": 2200000,
  "Manufacturer_Year": "2017-2019"
 },
 {
  "Make": "TATA",
  "PL": "Signa 4825",
  "PPL": "MAV 48",
  "Rajasthan": 2200000,
  "Maharashtra": 2275000,
  "Gujarat": 2600000,
  "Karnataka": 2500000,
  "Jharkhand": 2300000,
  "West Bengal": 2350000,
  "Uttar Pradesh": 2050000,
  "Delhi": 2350000,
  "Haryana": 1450000,
  "APTS": 2700000,
  "Madhya Pradesh": 2150000,
  "Chattisgarh": 2250000,
  "Tamil Nadu": 2500000,
  "Orissa": 2900000,
  "Bihar": 2000000,
  "Punjab": 2250000,
  "HP": 2000000,
  "J&K": 2050000,
  "Kerala": 2500000,
  "Min": 1450000,
  "Max": 2900000,
  "Manufacturer_Year": "2017-2019"
 },
 {
  "Make": "TATA",
  "PL": "LPS4018TC IAL",
  "PPL": "Tractor Trailer 39.5",
  "Rajasthan": 1150000,
  "Maharashtra": 1425000,
  "Gujarat": 1500000,
  "Karnataka": 1150000,
  "Jharkhand": 1900000,
  "West Bengal": 1575000,
  "Uttar Pradesh": 1400000,
  "Delhi": 1525000,
  "Haryana": 1150000,
  "APTS": 1700000,
  "Madhya Pradesh": 1040000,
  "Chattisgarh": 1120000,
  "Tamil Nadu": 1300000,
  "Orissa": 1200000,
  "Bihar": 1200000,
  "Punjab": 1200000,
  "HP": 1400000,
  "J&K": 1430000,
  "Kerala": 1100000,
  "Min": 1040000,
  "Max": 1900000,
  "Manufacturer_Year": "2017-2019"
 },
 {
  "Make": "TATA",
  "PL": "Signa 4225",
  "PPL": "MAV 42",
  "Rajasthan": 1850000,
  "Maharashtra": 2200000,
  "Gujarat": 2300000,
  "Karnataka": 2300000,
  "Jharkhand": 2200000,
  "West Bengal": 2000000,
  "Uttar Pradesh": 2200000,
  "Delhi": 2175000,
  "Haryana": 1450000,
  "APTS": 2600000,
  "Madhya Pradesh": 2350000,
  "Chattisgarh": 2500000,
  "Tamil Nadu": 2400000,
  "Orissa": 2300000,
  "Bihar": 1800000,
  "Punjab": 2250000,
  "HP": 1550000,
  "J&K": 1540000,
  "Min": 1450000,
  "Max": 2600000,
  "Manufacturer_Year": "2017-2019"
 },
 {
  "Make": "TATA",
  "PL": "LPS4625TC",
  "PPL": "Tractor Trailer 46",
  "Rajasthan": 1300000,
  "Maharashtra": 1500000,
  "Gujarat": 1550000,
  "Karnataka": 1150000,
  "Jharkhand": 1700000,
  "West Bengal": 1500000,
  "Uttar Pradesh": 1550000,
  "Delhi": 1625000,
  "Haryana": 1050000,
  "Madhya Pradesh": 1400000,
  "Chattisgarh": 1350000,
  "Tamil Nadu": 1300000,
  "Orissa": 1400000,
  "Bihar": 1400000,
  "Punjab": 1450000,
  "HP": 1600000,
  "J&K": 1610000,
  "Min": 1050000,
  "Max": 1700000,
  "Manufacturer_Year": "2017-2019"
 },
 {
  "Make": "TATA",
  "PL": "LPT 4825",
  "PPL": "MAV 48",
  "Rajasthan": 2600000,
  "Maharashtra": 2700000,
  "Gujarat": 2550000,
  "Karnataka": 2300000,
  "Jharkhand": 2650000,
  "West Bengal": 3075000,
  "Uttar Pradesh": 2450000,
  "Delhi": 2550000,
  "Haryana": 2200000,
  "APTS": 2000000,
  "Tamil Nadu": 2400000,
  "Orissa": 3350000,
  "Bihar": 2200000,
  "Punjab": 2700000,
  "HP": "NA",
  "J&K": "NA",
  "Kerala": 2400000,
  "Min": 2000000,
  "Max": 3350000,
  "Manufacturer_Year": "2020-2021 (BS VI)"
 },
 {
  "Make": "TATA",
  "PL": "SIGNA 4825.TK. FBV",
  "PPL": "MAV Tippers 48",
  "Rajasthan": 2750000,
  "Maharashtra": 3000000,
  "Gujarat": 2550000,
  "Karnataka": 2500000,
  "Jharkhand": 2700000,
  "West Bengal": 2925000,
  "Uttar Pradesh": 2500000,
  "Delhi": 2450000,
  "Haryana": 2350000,
  "APTS": 2000000,
  "Tamil Nadu": 2500000,
  "Orissa": 3550000,
  "Bihar": 2100000,
  "Punjab": 2900000,
  "HP": 2300000,
  "J&K": 2340000,
  "Kerala": 2500000,
  "Min": 2000000,
  "Max": 3550000,
  "Manufacturer_Year": "2020-2021 (BS VI)"
 },
 {
  "Make": "TATA",
  "PL": "LPS5530TC",
  "PPL": "Tractor Trailer 55",
  "Rajasthan": 2250000,
  "Maharashtra": 2000000,
  "Gujarat": 2450000,
  "Karnataka": 1900000,
  "Jharkhand": 2200000,
  "West Bengal": 2375000,
  "Uttar Pradesh": 1830000,
  "Delhi": 2025000,
  "Haryana": 1750000,
  "APTS": 1850000,
  "Tamil Nadu": 2100000,
  "Orissa": 2200000,
  "Bihar": 1850000,
  "Punjab": 2600000,
  "HP": "NA",
  "J&K": "NA",
  "Kerala": 1800000,
  "Min": 1750000,
  "Max": 2450000,
  "Manufacturer_Year": "2020-2021 (BS VI)"
 },
 {
  "Make": "TATA",
  "PL": "LPS5525TC 4x2",
  "PPL": "Tractor Trailer 52\/55",
  "Rajasthan": 2000000,
  "Maharashtra": 1700000,
  "Gujarat": 2100000,
  "Karnataka": 1650000,
  "Jharkhand": 1800000,
  "West Bengal": 2375000,
  "Uttar Pradesh": 1830000,
  "Delhi": 1965000,
  "Haryana": 1900000,
  "APTS": 1500000,
  "Tamil Nadu": 1700000,
  "Orissa": 1960000,
  "Bihar": 1750000,
  "Punjab": 2400000,
  "HP": "NA",
  "J&K": "NA",
  "Kerala": 1800000,
  "Min": 1500000,
  "Max": 2375000,
  "Manufacturer_Year": "2020-2021 (BS VI)"
 },
 {
  "Make": "TATA",
  "PL": "SIGNA 2823.K. FBV",
  "PPL": "MAV Tippers 28",
  "Rajasthan": 2000000,
  "Maharashtra": 2300000,
  "Gujarat": 2000000,
  "Karnataka": 1900000,
  "Jharkhand": 1800000,
  "West Bengal": 2400000,
  "Uttar Pradesh": 2030000,
  "Delhi": 2100000,
  "Haryana": 1950000,
  "APTS": 1750000,
  "Tamil Nadu": 2000000,
  "Orissa": 2000000,
  "Bihar": 1450000,
  "Punjab": 2100000,
  "HP": 1900000,
  "J&K": 1870000,
  "Kerala": 2000000,
  "Min": 1450000,
  "Max": 2400000,
  "Manufacturer_Year": "2020-2021 (BS VI)"
 },
 {
  "Make": "TATA",
  "PL": "LPT 4225",
  "PPL": "MAV 42",
  "Rajasthan": 2300000,
  "Maharashtra": 2500000,
  "Gujarat": 2600000,
  "Karnataka": 2100000,
  "Jharkhand": 2500000,
  "West Bengal": 2850000,
  "Uttar Pradesh": 2130000,
  "Delhi": 2350000,
  "Haryana": 2050000,
  "APTS": 1800000,
  "Tamil Nadu": 2200000,
  "Orissa": 2500000,
  "Bihar": 2000000,
  "Punjab": 2500000,
  "HP": 1930000,
  "J&K": 1890000,
  "Min": 1800000,
  "Max": 2850000,
  "Manufacturer_Year": "2020-2021 (BS VI)"
 },
 {
  "Make": "TATA",
  "PL": "SIGNA 3525.TK. FBV",
  "PPL": "MAV Tippers 35",
  "Rajasthan": 2400000,
  "Maharashtra": 2500000,
  "Gujarat": 2100000,
  "Karnataka": 2000000,
  "Jharkhand": 2200000,
  "West Bengal": 2650000,
  "Uttar Pradesh": 2230000,
  "Delhi": 2400000,
  "Haryana": 2200000,
  "APTS": 1700000,
  "Tamil Nadu": 2000000,
  "Orissa": 2200000,
  "Bihar": 1850000,
  "Punjab": 2700000,
  "HP": 1900000,
  "J&K": 1910000,
  "Min": 1700000,
  "Max": 2650000,
  "Manufacturer_Year": "2020-2021 (BS VI)"
 },
 {
  "Make": "TATA",
  "PL": "SIGNA 2825.K. FBV",
  "PPL": "MAV Tippers 28",
  "Rajasthan": 2150000,
  "Maharashtra": 2500000,
  "Gujarat": 2355000,
  "Karnataka": 1700000,
  "Jharkhand": 2300000,
  "West Bengal": 2475000,
  "Uttar Pradesh": 2030000,
  "Delhi": 2250000,
  "Haryana": 1950000,
  "APTS": 1300000,
  "Tamil Nadu": 1600000,
  "Orissa": 2050000,
  "Bihar": 1500000,
  "Punjab": 2200000,
  "HP": 1775000,
  "J&K": 1700000,
  "Kerala": 1500000,
  "Min": 1300000,
  "Max": 2500000,
  "Manufacturer_Year": "2020-2021 (BS VI)"
 },
 {
  "Make": "TATA",
  "PL": "LPT 2818",
  "PPL": "MAV 28",
  "Rajasthan": 1550000,
  "Maharashtra": 1600000,
  "Gujarat": 1950000,
  "Karnataka": 1500000,
  "Jharkhand": 1750000,
  "West Bengal": 2000000,
  "Uttar Pradesh": 1530000,
  "Delhi": 1800000,
  "Haryana": 1600000,
  "APTS": 1450000,
  "Tamil Nadu": 1650000,
  "Orissa": 1500000,
  "Bihar": 1500000,
  "Punjab": 1800000,
  "HP": 1750000,
  "J&K": 1770000,
  "Kerala": 1650000,
  "Min": 1450000,
  "Max": 2000000,
  "Manufacturer_Year": "2020-2021 (BS VI)"
 },
 {
  "Make": "TATA",
  "PL": "LPT 3518",
  "PPL": "MAV 35",
  "Rajasthan": 1700000,
  "Maharashtra": 1900000,
  "Gujarat": 2300000,
  "Karnataka": 1600000,
  "Jharkhand": 1950000,
  "West Bengal": 2350000,
  "Uttar Pradesh": 2180000,
  "Delhi": 2200000,
  "Haryana": 1900000,
  "APTS": 1500000,
  "Tamil Nadu": 1700000,
  "Orissa": 1900000,
  "Bihar": 1700000,
  "Punjab": 2100000,
  "HP": 1900000,
  "J&K": 1890000,
  "Kerala": 1300000,
  "Min": 1500000,
  "Max": 2350000,
  "Manufacturer_Year": "2020-2021 (BS VI)"
 },
 {
  "Make": "TATA",
  "PL": "Signa 4825",
  "PPL": "MAV 48",
  "Rajasthan": 2600000,
  "Maharashtra": 2500000,
  "Gujarat": 2655000,
  "Karnataka": 2300000,
  "Jharkhand": 2500000,
  "West Bengal": 2950000,
  "Uttar Pradesh": 2180000,
  "Delhi": 2450000,
  "Haryana": 2300000,
  "APTS": 1900000,
  "Tamil Nadu": 2300000,
  "Orissa": 3000000,
  "Bihar": 2200000,
  "Punjab": 2600000,
  "HP": 2200000,
  "J&K": 2210000,
  "Kerala": 2300000,
  "Min": 1900000,
  "Max": 3000000,
  "Manufacturer_Year": "2020-2021 (BS VI)"
 },
 {
  "Make": "TATA",
  "PL": "LPS4018TC IAL",
  "PPL": "Tractor Trailer 39.5",
  "Rajasthan": 1550000,
  "Maharashtra": 1500000,
  "Gujarat": 1700000,
  "Karnataka": 1500000,
  "Jharkhand": 1600000,
  "West Bengal": 2150000,
  "Uttar Pradesh": 1530000,
  "Delhi": 1625000,
  "Haryana": 1450000,
  "APTS": 1350000,
  "Tamil Nadu": 1450000,
  "Orissa": 1400000,
  "Bihar": 1400000,
  "Punjab": 1700000,
  "HP": 1600000,
  "J&K": 1570000,
  "Min": 1350000,
  "Max": 2150000,
  "Manufacturer_Year": "2020-2021 (BS VI)"
 },
 {
  "Make": "TATA",
  "PL": "Signa 4225",
  "PPL": "MAV 42",
  "Rajasthan": 2300000,
  "Maharashtra": 2000000,
  "Gujarat": 2450000,
  "Karnataka": 2000000,
  "Jharkhand": 2100000,
  "West Bengal": 2600000,
  "Uttar Pradesh": 2330000,
  "Delhi": 2400000,
  "Haryana": 2150000,
  "APTS": 1700000,
  "Tamil Nadu": 2100000,
  "Orissa": 2450000,
  "Bihar": 2000000,
  "Punjab": 2600000,
  "HP": 1700000,
  "J&K": 1665000,
  "Kerala": 1900000,
  "Min": 1700000,
  "Max": 2600000,
  "Manufacturer_Year": "2020-2021 (BS VI)"
 },
 {
  "Make": "TATA",
  "PL": "LPS4625TC",
  "PPL": "Tractor Trailer 46",
  "Rajasthan": 1700000,
  "Maharashtra": 1700000,
  "Gujarat": 1700000,
  "Karnataka": 1500000,
  "Jharkhand": 1650000,
  "West Bengal": 2075000,
  "Uttar Pradesh": 1680000,
  "Delhi": 1725000,
  "Haryana": 1600000,
  "APTS": 1500000,
  "Orissa": 1650000,
  "Bihar": 1500000,
  "Punjab": 1600000,
  "HP": 1750000,
  "J&K": 1730000
 },
 {
  "Make": "TATA",
  "PL": "1512 LPT",
  "PPL": "ICV 15T - 16T",
  "Rajasthan": 1225000,
  "Maharashtra": 1300000,
  "Gujarat": 1100000,
  "Karnataka": 900000,
  "Jharkhand": 1250000,
  "West Bengal": 1050000,
  "Uttar Pradesh": 1250000,
  "Delhi": 1206607.5,
  "Haryana": 1000000,
  "APTS": 950000,
  "Madhya Pradesh": 1000000,
  "Chattisgarh": 1175000,
  "Tamil Nadu": 1250000,
  "Orissa": 1130000,
  "Bihar": 1150000,
  "Punjab": 1000000,
  "Min": 900000,
  "Max": 1300000,
  "Manufacturer_Year": "2017-2019"
 },
 {
  "Make": "TATA",
  "PL": "1109g LPT",
  "PPL": "ICV 10T - 12T",
  "Rajasthan": 1000000,
  "Maharashtra": 1100000,
  "Jharkhand": 900000,
  "West Bengal": 750000,
  "Uttar Pradesh": 1050000,
  "Delhi": 1250000,
  "Haryana": 1150000,
  "Min": 750000,
  "Max": 1250000,
  "Manufacturer_Year": "2017-2019"
 },
 {
  "Make": "TATA",
  "PL": "1512g LPT",
  "PPL": "ICV 15T - 16T",
  "Rajasthan": 1000000,
  "Maharashtra": 1075000,
  "Karnataka": 1450000,
  "Jharkhand": 1100000,
  "West Bengal": 750000,
  "Delhi": 1350000,
  "Haryana": 1275000,
  "Min": 750000,
  "Max": 1450000
 },
 {
  "Make": "TATA",
  "PL": "SIGNA 1923.K. FBV",
  "PPL": "MCV Tippers 19",
  "Rajasthan": 1500000,
  "Maharashtra": 1550000,
  "Gujarat": 1450000,
  "Karnataka": 1050000,
  "Jharkhand": 1700000,
  "West Bengal": 1050000,
  "Uttar Pradesh": 1400000,
  "Haryana": "NA",
  "APTS": 1350000,
  "Madhya Pradesh": 1700000,
  "Chattisgarh": 1450000,
  "Tamil Nadu": 1500000,
  "Punjab": 1250000,
  "Min": 1050000,
  "Max": 1700000,
  "Manufacturer_Year": "2017-2019"
 },
 {
  "Make": "TATA",
  "PL": "LPT 1918",
  "PPL": "MCV LPT 19",
  "Rajasthan": 1300000,
  "Maharashtra": 1325000,
  "Gujarat": 1300000,
  "Karnataka": 850000,
  "Jharkhand": 1400000,
  "West Bengal": 1200000,
  "Uttar Pradesh": 1350000,
  "Delhi": 1317795.3499999999,
  "Haryana": 1375000,
  "APTS": 875000,
  "Madhya Pradesh": 900000,
  "Chattisgarh": 1450000,
  "Tamil Nadu": 1450000,
  "Orissa": 900000,
  "Punjab": 1200000,
  "Min": 850000,
  "Max": 1450000
 },
 {
  "Make": "TATA",
  "PL": "1212 LPT",
  "PPL": "ICV 10T - 12T",
  "Rajasthan": 1100000,
  "Maharashtra": 1175000,
  "Gujarat": 900000,
  "Jharkhand": 950000,
  "West Bengal": 800000,
  "Uttar Pradesh": 1080000,
  "Haryana": 1100000,
  "APTS": 900000,
  "Madhya Pradesh": 900000,
  "Orissa": 1020000,
  "Bihar": 1045000,
  "Punjab": 850000,
  "Min": 800000,
  "Max": 1175000
 },
 {
  "Make": "TATA",
  "PL": "LPT1918 5L",
  "PPL": "MCV LPT 19",
  "Rajasthan": 1040000,
  "Maharashtra": 1100000,
  "Gujarat": 1150000,
  "Jharkhand": 1100000,
  "West Bengal": 900000,
  "Uttar Pradesh": 1000000,
  "Delhi": 1317795.3499999999,
  "Haryana": 1100000,
  "APTS": 1000000,
  "Madhya Pradesh": 1000000,
  "Chattisgarh": 1400000,
  "Tamil Nadu": 1400000,
  "Punjab": 1000000,
  "Min": 900000,
  "Max": 1400000,
  "Manufacturer_Year": "2017-2019"
 },
 {
  "Make": "TATA",
  "PL": "709g LPT",
  "PPL": "LCV 7T",
  "Rajasthan": 500000,
  "Maharashtra": 1071325.7780698084,
  "Karnataka": 1032906.6666666667,
  "Jharkhand": 700000,
  "West Bengal": 600000,
  "Delhi": 909847.9333333332,
  "Haryana": 900000,
  "Bihar": 650000,
  "Min": 500000,
  "Max": 1071325.7780698084
 },
 {
  "Make": "TATA",
  "PL": "407 GOLD",
  "PPL": "LCV 4T PU",
  "Rajasthan": 525000,
  "Maharashtra": 793746.196835686,
  "Gujarat": 700000,
  "Karnataka": 880306.6666666666,
  "Jharkhand": 750000,
  "West Bengal": 400000,
  "Uttar Pradesh": 620000,
  "Delhi": 900000,
  "Haryana": 450000,
  "APTS": 500000,
  "Madhya Pradesh": 500000,
  "Chattisgarh": 769212.28125,
  "Tamil Nadu": 769212.28125,
  "Orissa": 370000,
  "Bihar": 350000,
  "Punjab": 400000,
  "Min": 350000,
  "Max": 900000,
  "Manufacturer_Year": "2017-2019"
 },
 {
  "Make": "TATA",
  "PL": "912 LPK",
  "PPL": "ICV Tipper",
  "Rajasthan": 950000,
  "Maharashtra": 985000,
  "Gujarat": 650000,
  "Karnataka": 860000,
  "Jharkhand": 1550000,
  "West Bengal": 600000,
  "Uttar Pradesh": 970000,
  "Delhi": 915552,
  "Haryana": "NA",
  "APTS": 700000,
  "Madhya Pradesh": 700000,
  "Chattisgarh": 1350000,
  "Tamil Nadu": 1200000,
  "Orissa": 680000,
  "Bihar": 700000,
  "Punjab": 600000,
  "Min": 600000,
  "Max": 1550000,
  "Manufacturer_Year": "2017-2019"
 },
 {
  "Make": "TATA",
  "PL": "710 LPT",
  "PPL": "LCV 7T",
  "Rajasthan": 800000,
  "Maharashtra": 875000,
  "Gujarat": 700000,
  "Karnataka": 725000,
  "Jharkhand": 800000,
  "West Bengal": 600000,
  "Uttar Pradesh": 820000,
  "Delhi": 700000,
  "Haryana": "NA",
  "APTS": 675000,
  "Madhya Pradesh": 600000,
  "Chattisgarh": 800000,
  "Tamil Nadu": 800000,
  "Orissa": 650000,
  "Bihar": 645000,
  "Punjab": 550000,
  "Min": 550000,
  "Max": 875000,
  "Manufacturer_Year": "2017-2019"
 },
 {
  "Make": "TATA",
  "PL": "610 SK",
  "PPL": "LCV Tippers",
  "Rajasthan": 650000,
  "Maharashtra": 700000,
  "Gujarat": 600000,
  "Karnataka": 904999.9999999999,
  "Jharkhand": 769212.28125,
  "West Bengal": 400000,
  "Uttar Pradesh": 650000,
  "Delhi": 769212.28125,
  "Haryana": "NA",
  "Chattisgarh": 769212.28125,
  "Tamil Nadu": 575000,
  "Orissa": 470000,
  "Bihar": 450000,
  "Punjab": 450000,
  "Min": 400000,
  "Max": 904999.9999999999
 },
 {
  "Make": "TATA",
  "PL": "1512 LPT",
  "PPL": "ICV 15T - 16T",
  "Maharashtra": 1450000,
  "Gujarat": 1200000,
  "Karnataka": 1100000,
  "Jharkhand": 1400000,
  "West Bengal": 1300000,
  "Uttar Pradesh": 1310000,
  "Delhi": 1050000,
  "Haryana": 1100000,
  "APTS": 1000000,
  "Madhya Pradesh": 1100000,
  "Punjab": 1150000,
  "Min": 1000000,
  "Max": 1450000,
  "Manufacturer_Year": "2020-21 (BS VI)"
 },
 {
  "Make": "TATA",
  "PL": "1109g LPT",
  "PPL": "ICV 10T - 12T",
  "Maharashtra": 1300000,
  "Gujarat": 950000,
  "Karnataka": 7500000,
  "Jharkhand": 950000,
  "West Bengal": "NA",
  "Uttar Pradesh": 1080000,
  "Delhi": 1000000,
  "Haryana": 1225000,
  "Madhya Pradesh": 850000,
  "Min": 850000,
  "Max": 7500000,
  "Manufacturer_Year": "2020-21 (BS VI)"
 },
 {
  "Make": "TATA",
  "PL": "1512g LPT",
  "PPL": "ICV 15T - 16T",
  "Maharashtra": 1155000,
  "Gujarat": 1200000,
  "Karnataka": 1000000,
  "Jharkhand": 1300000,
  "West Bengal": 1250000,
  "Uttar Pradesh": 1150000,
  "Delhi": 1250000,
  "Haryana": 1350000,
  "Madhya Pradesh": 1000000,
  "Min": 1000000,
  "Max": 1350000,
  "Manufacturer_Year": "2020-21 (BS VI)"
 },
 {
  "Make": "TATA",
  "PL": "SIGNA 1923.K. FBV",
  "PPL": "MCV Tippers 19",
  "Maharashtra": 1675000,
  "Gujarat": 1300000,
  "Karnataka": 1200000,
  "Jharkhand": 1700000,
  "West Bengal": 1375000,
  "Uttar Pradesh": 1460000,
  "Delhi": 1150000,
  "Haryana": "NA",
  "APTS": 1200000,
  "Madhya Pradesh": 1300000,
  "Punjab": 1350000,
  "Min": 1150000,
  "Max": 1700000,
  "Manufacturer_Year": "2020-21 (BS VI)"
 },
 {
  "Make": "TATA",
  "PL": "LPT 1918",
  "PPL": "MCV LPT 19",
  "Maharashtra": 1450000,
  "Gujarat": 1300000,
  "Karnataka": 1200000,
  "Jharkhand": 1550000,
  "West Bengal": 1185000,
  "Uttar Pradesh": 1410000,
  "Delhi": 1500000,
  "Haryana": 1450000,
  "APTS": 1200000,
  "Madhya Pradesh": 1200000,
  "Punjab": 1300000,
  "Min": 1185000,
  "Max": 1550000,
  "Manufacturer_Year": "2020-21 (BS VI)"
 },
 {
  "Make": "TATA",
  "PL": "1212 LPT",
  "PPL": "ICV 10T - 12T",
  "Maharashtra": 1235000,
  "Gujarat": 1100000,
  "Karnataka": 1000000,
  "Jharkhand": 1050000,
  "West Bengal": 960000,
  "Uttar Pradesh": 1140000,
  "Delhi": 1100000,
  "Haryana": 1150000,
  "APTS": 950000,
  "Madhya Pradesh": 1100000,
  "Punjab": 1000000,
  "Min": 950000,
  "Max": 1235000,
  "Manufacturer_Year": "2020-21 (BS VI)"
 },
 {
  "Make": "TATA",
  "PL": "LPT1918 5L",
  "PPL": "MCV LPT 19",
  "Maharashtra": 1200000,
  "Gujarat": 1000000,
  "Karnataka": 1000000,
  "Jharkhand": 1300000,
  "West Bengal": 1250000,
  "Uttar Pradesh": 1060000,
  "Delhi": 1150000,
  "Haryana": 1150000,
  "APTS": 900000,
  "Madhya Pradesh": 950000,
  "Punjab": 1100000,
  "Min": 900000,
  "Max": 1300000,
  "Manufacturer_Year": "2020-21 (BS VI)"
 },
 {
  "Make": "TATA",
  "PL": "709g LPT",
  "PPL": "LCV 7T",
  "Maharashtra": 1125000,
  "Gujarat": 700000,
  "Karnataka": 650000,
  "Jharkhand": 800000,
  "West Bengal": 650000,
  "Uttar Pradesh": 900000,
  "Delhi": 920000,
  "Haryana": 950000,
  "Madhya Pradesh": 700000,
  "Min": 650000,
  "Max": 1125000,
  "Manufacturer_Year": "2020-21 (BS VI)"
 },
 {
  "Make": "TATA",
  "PL": "407 GOLD",
  "PPL": "LCV 4T PU",
  "Maharashtra": 835000,
  "Gujarat": 800000,
  "Karnataka": 5500000,
  "Jharkhand": 800000,
  "West Bengal": 500000,
  "Uttar Pradesh": 680000,
  "Delhi": 600000,
  "Haryana": 475000,
  "APTS": 600000,
  "Madhya Pradesh": 600000,
  "Punjab": 600000,
  "Min": 475000,
  "Max": 5500000,
  "Manufacturer_Year": "2020-21 (BS VI)"
 },
 {
  "Make": "TATA",
  "PL": "912 LPK",
  "PPL": "ICV Tipper",
  "Maharashtra": 1015000,
  "Gujarat": 800000,
  "Karnataka": 7500000,
  "Jharkhand": 1000000,
  "West Bengal": 700000,
  "Uttar Pradesh": 1030000,
  "Delhi": 650000,
  "Haryana": "NA",
  "APTS": 800000,
  "Madhya Pradesh": 850000,
  "Punjab": 750000,
  "Min": 650000,
  "Max": 7500000,
  "Manufacturer_Year": "2020-21 (BS VI)"
 },
 {
  "Make": "TATA",
  "PL": "710 LPT",
  "PPL": "LCV 7T",
  "Maharashtra": 915000,
  "Gujarat": 850000,
  "Karnataka": 7000000,
  "Jharkhand": 1150000,
  "West Bengal": 700000,
  "Uttar Pradesh": 880000,
  "Delhi": 650000,
  "Haryana": "NA",
  "APTS": 700000,
  "Madhya Pradesh": 800000,
  "Punjab": 650000,
  "Min": 650000,
  "Max": 7000000,
  "Manufacturer_Year": "2020-21 (BS VI)"
 },
 {
  "Make": "TATA",
  "PL": "610 SK",
  "PPL": "LCV Tippers",
  "Maharashtra": 7350000,
  "Gujarat": 650000,
  "Karnataka": 6500000,
  "Jharkhand": 650000,
  "West Bengal": 550000,
  "Uttar Pradesh": 710000,
  "Delhi": 550000,
  "Haryana": "NA",
  "APTS": 650000,
  "Madhya Pradesh": 700000,
  "Punjab": 600000,
  "Min": 550000,
  "Max": 7350000,
  "Manufacturer_Year": "2020-21 (BS VI)"
 }
]
  testing: any=[];
 
  constructor(
    private confirmService: AppConfirmService,
    private commonservice: CommonService,
    private loader: AppLoaderService,
    private router: Router,
    private Passdata: DataPassService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<TsmProductListComponent>,
    private datePipe: DatePipe,
    private InvenService: InventoryService,
    private fb: FormBuilder,
  ) {
    this.config = {
      itemsPerPage: 0,
      currentPage: 0,
      totalItems: 0,
    };
    {
      this.confirmForm = this.fb.group({
        remarks: ['', Validators.required]
      });}
  }

  isPageUrlCheck: any
  filterFields: boolean = false;
  isRegional: boolean = false;

  ngOnInit() {
    this.SessionData = this.commonservice.getUserDetails();
    // if (this.SessionData.position == 'Tata OK TSM') {
    //   const ResponseData: inputData = {} as inputData;
    //   ResponseData.offset = 0 ;
    //   this.tsmDatalist(ResponseData)
    // }
    
    var list = JSON.parse(localStorage.getItem('PageDetails'))
    for (let entry of list) {
      if (entry.page_detail.length > 0) {
        for (let entryDetail of entry.page_detail) {
          if (entryDetail.page_url == 'ProductList') {
            this.isPageUrlCheck = "ProductList"
            this.readable = entryDetail.readable
          }
        }
      }
    }

    // if (this.isPageUrlCheck == "ProductList") {
      this.scrollToBottom();
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
      this.DivisionId = localStorage.getItem("DivisionId");
      this.checkTML = "TML";
      // var TMLdata = {
      //   app_name: "com.tatamotors.tataoksourcing",
      //   app_version: "1.2",
      //   dev_id: "897jhjh6",
      //   offset: 0,
      //   size: 1000,
      //   //brand_type: this.checkTML,
      //   brand_type: "all",
      //   applications: ["Market Load Operators"],
      //   budget_category: "0-* ",
      //   gvw_from: "all",
      //   gvw_to: "all",
      //   hsn_include: ["all"],
      //   model_vc_number: "all",
      // };
      // this.GetData(TMLdata);
      const ResponseData: inputData = {} as inputData;
      ResponseData.offset = 0;
      // ResponseData.expired = false;
      ResponseData.visibility = this.visiblitydata;
      ResponseData.params_approval_status =this.params_approval
      // this.getInventoryList(ResponseData);
      this.tsmDatalist(ResponseData)
    // }
    // else {
    //   this.router.navigate(["pages/ErrorPage"]);
    // }
    this.checkMobileView();
    window.addEventListener('resize', this.checkMobileView.bind(this));
  }
  checkMobileView() {
    this.isMobile = window.matchMedia('(max-width: 768px)').matches;
  }
  refreshListing() {
    this.isApiCalled = true;
    const ResponseData: inputData = {} as inputData;
    ResponseData.offset = 0;
    ResponseData.visibility = this.visiblitydata;
    ResponseData.params_approval_status ='INITIATED'
    this.tsmDatalist(ResponseData);
  }
  scrollToBottom(): void {
    try {
      this.scrollBottom.nativeElement.scrollTop = 0;
    } catch (err) { }
  }
  getchassis = [];
  AddVehicle(data: any = {}) {
    localStorage.removeItem("vc_number");
    localStorage.setItem("vc_number", data.vc_number);
    localStorage.removeItem("model_id");
    localStorage.setItem("model_id", data.model_id);
    this.Passdata.setStockData(data);
    this.router.navigate(["pages/InventoryMaster/"]);
  }

  Checkchassis_number: [];
  hidebutton: boolean = false;
  label: string;
  tempdata = [];
  tempdata1 = []
  tSMDATA :any []
  tSMDATAPARK = []
  getInventoryList(json) {
    this.tempdata = [];
    this.PANIndiaData = [];
    this.regionalData = [];
    this.loader.open();
    this.config.totalItems = 0

    this.commonservice.GetInventoryList(json).subscribe(
      (res) => {
        this.loader.close();
        this.isApiCalled = false;
        if (res.success) {
          this.totalCount = res.total_count;
          if (this.visiblitydata == "private") {
            this.tempdata = res.data;
          }
          else if (this.visiblitydata == "public") {
            this.PANIndiaData = res.data;
          }
          else {
            this.regionalData = res.data;
          }
          // this.config.currentPage = 1;
          this.config.itemsPerPage = 10
          this.config.totalItems = res.total_count;

          this.dialog.closeAll();

        } else {
          this.totalCount = 0;
          const errorMessage = res;
          // return;
        }
      },
      (err) => {
        this.isApiCalled = false;
        this.loader.close();
        console.log("error occured", err);
      }
    );
  }

  getDaysLeft(rowData: any): number {
    let date1 = this.datePipe.transform(rowData.created_at, 'yyyy/MM/dd');
    const fromDate = new Date(date1);
    const toDate = new Date();
    const thirtyDaysLater = new Date(fromDate);
    // let daysInMonth = this.GFG_Fun();
    let daysInMonth = 30;
    thirtyDaysLater.setDate(thirtyDaysLater.getDate() + daysInMonth);



    // Get the time difference in milliseconds
    if (fromDate) {


      if (!rowData.extend) {

        const timeDifference = toDate.getTime() - fromDate.getTime();
        const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

        return 31 - daysDifference;
      } else {

        const timeDifference = fromDate.getTime() - toDate.getTime();
        const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
        return daysDifference;
      }
    }

    // Convert milliseconds to days

  }
  // calculateDaysValidity(createdDate: Date): number {
  //   const currentDate = new Date();
  //   const timeDifference = currentDate.getTime() - createdDate.getTime();
  //   const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  //   return daysDifference;
  // }
  calculateDiff(dateSent) {
    let currentDate = new Date();
    dateSent = new Date(dateSent);

    return Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24));
  }
  daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  GFG_Fun() {
    let date = new Date();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    // console.log("Number of days in " + month
    //   + "th month of the year " + year
    //   + " is " + this.daysInMonth(month, year));
    return this.daysInMonth(month, year);
  }

  off: number;
  pageChanged(event) {
    document.body.scrollTop = 0;
    var offset = event;
    this.off = event;
    if (offset > 0) {
      offset = offset - 1;
    }
    this.config.currentPage = event;
    this.p = event;

    const ResponseData: inputData = {} as inputData;
    ResponseData.offset = offset ;
    ResponseData.visibility = this.visiblitydata;
    if (this.visiblitydata == "") {
      var loginData = JSON.parse(localStorage.getItem('loginData'));
      ResponseData.region = loginData.region;
    }
    // this.getInventoryList(ResponseData);
  }

  openDialog(flag, rowData): void {
    let daysLeft = this.getDaysLeft(rowData);
    let tempVisibility = this.visiblitydata == "private" ? "Dealer Only" : this.visiblitydata == "public" ? "PAN India" : "region";
    const dialogRef = this.dialog.open(AddProductComponent, {
      width: '50%',
      data: { data: rowData, flag: flag, visibility: tempVisibility, daysLeft: daysLeft ,isTSM :true },
      disableClose: true

    });

    // cancle popup api calling
    dialogRef.afterClosed().subscribe(result => {
      // console.log('here', result)
      // if (result === 'saved') {commented because load api on cancle for image delete functionality
        // Perform actions after the dialog is closed with save
        const ResponseData: inputData = {} as inputData;
        ResponseData.offset = 0;
        ResponseData.visibility = this.visiblitydata;
        if (this.visiblitydata == "") {
          var loginData = JSON.parse(localStorage.getItem('loginData'));
          ResponseData.region = loginData.region;
        }
        // this.getInventoryList(ResponseData);
      // }
    });
  }
  TabReader(index) {
    this.isApiCalled = true;
    this.isApiCalledMyStock = false;
    this.isApiCalledPan = false;
    this.isApiCalledRegion = false;
    const ResponseData: inputData = {} as inputData;
    this.popupHandler();
    this.isRegional = false;
    this.selectedTabIndex = index;
    if (index == 0) {
      this.isApiCalledMyStock = true;
      this.tempdata = [];
      this.visiblitydata = "2";
      ResponseData.offset = 0;
      ResponseData.visibility = this.visiblitydata;
    }
 else {
      this.isApiCalledRegion = true;
      this.regionalData = [];
      this.isRegional = true;
      this.visiblitydata = "3";
      var loginData = JSON.parse(localStorage.getItem('loginData'));
      ResponseData.region = loginData.region;
      ResponseData.offset = 0;
      ResponseData.visibility = this.visiblitydata;

    }
    ResponseData.params_approval_status ='INITIATED'
    this.config.offset = 0;
    this.config.currentPage = 0;
    this.tsmDatalist(ResponseData);
  }

  dealerInfo(index, flag) {

    if (flag == 'mystocks') {
      if (this.previousIndex != null && (index != this.previousIndex)) {
        this.tempdata[this.previousIndex].isDealer = false
      }

      if (this.tempdata[index].isDealer) {
        this.tempdata[index].isDealer = !this.tempdata[index].isDealer
      }
      else {
        this.tempdata[index].isDealer = true
      }
    }
    else if (flag == 'regionalstocks') {
      if (this.previousIndex != null && (index != this.previousIndex)) {
        this.regionalData[this.previousIndex].isDealer = false
      }
      if (this.regionalData[index].isDealer) {
        this.regionalData[index].isDealer = !this.regionalData[index].isDealer
      }
      else {
        this.regionalData[index].isDealer = true;
      }
    }
    this.previousIndex = index;
    // this.openDealerDialog()  
  }
  // openDealerDialog(): void {
  //   const dialogRef = this.dialog.open(DialogComponent, {
  //     width: '250px',
  //     data: {name: this.name, animal: this.animal}
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log('The dialog was closed');
  //     this.animal = result;
  //   });
  // }

  formControlValue(req) {
    var loginData = JSON.parse(localStorage.getItem('loginData'));
    const ResponseData: inputData = {} as inputData;
    ResponseData.visibility = this.visiblitydata;
    ResponseData.offset = 0;

    if (req == "reset") {
      if (this.visiblitydata == "") {
        ResponseData.region = loginData.region;
      }
      ResponseData.params_approval_status='INITIATED'
      this.params_approval=ResponseData.params_approval_status
      this.tsmDatalist(ResponseData);
    }
    else {

      ResponseData.state = req.state;
      ResponseData.district = req.district;
      if (this.visiblitydata == "") {
        ResponseData.region = req.region ? req.region : loginData.region;
      }
      else {
        if (this.visiblitydata == "") {
          ResponseData.region = req.region ? req.region : "";
        }
      }
      ResponseData.no_of_owners = req.owner;
      ResponseData.expired = req.sold == "true" ? true : false;
      ResponseData.vehicle_type = req.vehicleType;
      ResponseData.finance_availability = req.finance;
      ResponseData.engine_warranty = req.warranty;
      ResponseData.emission = req.emission;
      ResponseData.params_approval_status= req.approval_status
      this.params_approval=ResponseData.params_approval_status

      this.tsmDatalist(ResponseData);
    }

  }

  openDealerInfo(rowData): void {
    let imagesArr = rowData.vehicle_documents.filter((item: any) => item.name == null);
    console.log(imagesArr);

    if (imagesArr.length == 0) {
      return;
    }
    else {
      const dialogRef = this.dialog.open(DealerInfoComponent, {
        disableClose: true, width: '50%',
        data: rowData
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === 'saved') {
          // Perform actions after the dialog is closed with save
          // const ResponseData: inputData = {} as inputData;
          // ResponseData.offset = 0;
          // ResponseData.visibility = this.visiblitydata;
          // this.getInventoryList(ResponseData);
        }
      });
    }
  }

  openImage(rowData) {
    var element = document.getElementById('popup1');
    element.style.display = 'block'
  }

  UpdateVehicleValidity(flag, rowData) {
    this.confirmService
      .confirm({ message: ` Are you sure you want to sold this vehicle ?` })
      .subscribe((data) => {
        if (data) {
          var json = {
            "vehicle_id": rowData.id,
            "action_type": flag,
            "extend_date": ""
          }

          this.loader.open();
          this.commonservice.updateVehicleValidity(json).subscribe(
            (res) => {
              this.loader.close();
              if (res.success) {
                // Swal.fire(res.data.msg);
                Swal.fire("Vehicle sold Successfully");
                const ResponseData: inputData = {} as inputData;
                ResponseData.offset = 0;
                ResponseData.expired = false;
                ResponseData.visibility = this.visiblitydata;
                // this.getInventoryList(ResponseData);
              } else {
                const errorMessage = res;
                Swal.fire(res.error.data.msg)
                // return;
              }
            },
            (err) => {
              this.loader.close();
              console.log("error occured", err);
            }
          );

        }
      });

  }
  Approve(flag, rowData) {
    this.confirmService
      .confirm({ message: ` Are you sure you want to Approve this vehicle ?` })
      .subscribe((data) => {
        if (data) {
          var json = {
            "vehicle_id": rowData.id,
            "params_approval_status": flag,
          }

          this.loader.open();
          this.commonservice.approveCall(json).subscribe(
            (res) => {
              this.loader.close();
              if (res.vehicle_status) {
                // Swal.fire(res.data.msg);
                Swal.fire("Vehicle Approved Successfully");
                const ResponseData: inputData = {} as inputData;
                ResponseData.offset = 0;
                ResponseData.visibility = this.visiblitydata;
                 ResponseData.params_approval_status =this.params_approval
                this.tsmDatalist(ResponseData);
              } else {
                const errorMessage = res;
                Swal.fire(res.error.data.msg)
                // return;
              }
            },
            (err) => {
              this.loader.close();
              console.log("error occured", err);
            }
          );

        }
      });

  } 

  DisApprove(flag, rowData) {
    this.confirmService
      .confirmremakrs({ message: ` Are you sure you want to DisApprove this vehicle ?` })
      .subscribe((data) => {
        if (data) {
          var json = {
            "vehicle_id": rowData.id,
            "params_approval_status": flag,
            "remarks":data['remarks'] ? data['remarks'] : ''
          }

          this.loader.open();
          this.commonservice.approveCall(json).subscribe(
            (res) => {
              this.loader.close();
              if (res.vehicle_status) {
                // Swal.fire(res.data.msg);
                Swal.fire("Vehicle Disapprove Successfully");
                const ResponseData: inputData = {} as inputData;
                ResponseData.offset = 0;
                ResponseData.visibility = this.visiblitydata;
                 ResponseData.params_approval_status =this.params_approval
                this.tsmDatalist(ResponseData);
              } else {
                const errorMessage = res;
                Swal.fire(res.error.data.msg)
                // return;
              }
            },
            (err) => {
              this.loader.close();
              console.log("error occured", err);
            }
          );

        }
      });

  }
  Reject(flag, rowData) {
    this.confirmService
      .confirm({ message: ` Are you sure you want to Reject this vehicle ?` })
      .subscribe((data) => {
        if (data) {
          var json = {
            "vehicle_id": rowData.id,
            "params_approval_status": flag,
          }

          this.loader.open();
          this.commonservice.approveCall(json).subscribe(
            (res) => {
              this.loader.close();
              if (res.vehicle_status) {
                // Swal.fire(res.data.msg);
                Swal.fire("Vehicle Rejected Successfully");
                const ResponseData: inputData = {} as inputData;
                ResponseData.offset = 0;
                ResponseData.visibility = this.visiblitydata;
                ResponseData.params_approval_status =this.params_approval
                this.tsmDatalist(ResponseData);
              } else {
                const errorMessage = res;
                Swal.fire(res.error.data.msg)
                // return;
              }
            },
            (err) => {
              this.loader.close();
              console.log("error occured", err);
            }
          );

        }
      });

  } 
   
  popupHandler() {
    this.filterFields = !this.filterFields;
  }

  tsmDatalist(page:any){
    this.loader.open();
    this.tSMDATA=[]
    this.tSMDATAPARK=[]
      this.config.totalItems = 0
    let json ={
      'offset': page.offset,
      'limit':10,
      'cat_id':Number(page.visibility),
      'params_approval_status': page.params_approval_status ? page.params_approval_status : ''
    }
    this.commonservice.TSMLeadsList(json).subscribe(
      (res) => {
        this.loader.close();
        this.isApiCalled = false;
        if (res.success) {
          this.totalCount = res.total_count;
            if (this.visiblitydata == "2") {
              this.tSMDATA = res.data;
              this.tSMDATA.forEach(element => {
                let data :any = [];
                let pplvalue = element.sub_cat_detail.name
                this.jsonData.forEach(e => {
                  if(e.PPL==pplvalue){
                    
                    data.push(e)
                    
                    // this.testing.push(e)
                    // this.tSMDATA.push(this.testing)
                  }
                  
                });
                element['pushedValue'] = data
              });
            }
            else {
              this.tSMDATAPARK = res.data;
              this.tSMDATAPARK.forEach(element => {
                let data :any = [];
                let pplvalue = element.sub_cat_detail.name
                this.jsonData.forEach(e => {
                  console.log(e)
                  if(e.PPL==pplvalue){
                    
                    data.push(e)
                    
                    // this.testing.push(e)
                    // this.tSMDATA.push(this.testing)
                  }
                  
                });
                element['pushedValue'] = data
              });
            }
            
          // this.config.currentPage = 1;
          this.config.itemsPerPage = 10
          this.config.totalItems = res.total_count;
          
          this.dialog.closeAll();

        } else {
          this.totalCount = 0;
          const errorMessage = res;
          // return;
        }
      },
      (err) => {
        this.isApiCalled = false;
        this.loader.close();
        console.log("error occured", err);
      }
    );
  }
  pageChangedTSM(event) {
    document.body.scrollTop = 0;
    var offset = event;
    this.off = event;
    if (offset > 0) {
      offset = offset - 1;
    }
    this.config.currentPage = event;
    this.p = event;

    const ResponseData: inputData = {} as inputData;
    ResponseData.offset = offset ;
    ResponseData.visibility = this.visiblitydata;
    ResponseData.params_approval_status =this.params_approval
      this.tsmDatalist(ResponseData)
  }
  EditInventoryData(InventoryID, isNew) {
    this.Passdata.resetOption();
    const objData: ApproveData = {} as ApproveData;
    objData.id = InventoryID;
    this.InvenService.ViewInventoryByIdtsm(objData).subscribe(res => {
      if (res instanceof HttpErrorResponse) {
        return;
      }
      if (res.success) {
        this.Passdata.setOption(res.data);
        if (isNew == "dealer")
          this.router.navigate(["pages/InventoryMasterTSM/"], { queryParams: { isDealer: true } });
        else {
          this.router.navigate(["pages/InventoryMasterTSM/"], { queryParams: { isDealer: false } });
        }
      } else {
        Swal.fire(res.data.msg, "Error");
      }
    });
    // this.InvenService.ViewInventoryById(objData).subscribe((data) => {
    //   if (data instanceof HttpErrorResponse) {
    //     return;
    //   }
    //   if (data.success) {
    //     this.Passdata.setOption(data.data);

    //     this.router.navigate(["pages/InventoryMaster/"]);
    //   } else {
    //     Swal.fire(data.data.msg, "Error");
    //   }
    // });
  }
  ViewInventory(data: any = {}, isNew?) {
    
    localStorage.removeItem("InventoryId");
    this.EditInventoryData(data.id, isNew);
    //localStorage.removeItem("InventoryId");
    localStorage.setItem("InventoryId", data.id);
    localStorage.removeItem("InventoryDetail");
    localStorage.setItem("InventoryDetail", JSON.stringify(data));
    localStorage.removeItem("InventoryStatus");
    localStorage.setItem("InventoryStatus", data.inventory_status);
    localStorage.removeItem("approved_status");
    localStorage.setItem("approved_status", data.approved_status);
    //this.router.navigate(["pages/InventoryMaster/"]);
    // this.Passdata.setStockData(data);
  }
  showPopup(data:any){
    this.confirmService
      .newpopup(data)
  }
  ngOnDestroy(): void {
    window.removeEventListener('resize', this.checkMobileView.bind(this));
  }
}

export class InventoryListData {
  is_cover: string;
  color: string;
  model_name: string;
  model_id: string;
  brand_type: string;
  ppl: string;
  ppl_id: string;
  msrp: string;
  lob: string;
  vc_number: string;
  registration_number: string;
  chassis_number: string;
  mfg_date: Date;
  Vehicle_cat: string;
  hsn: string;
  grows_weight: string
}
export class inputData {
  user_id: any;
  offset: number;
  text_search: string;
  visibility: string;
  state: string;
  max_odometer: number;
  min_odometer: number;
  city: string;
  district: string;
  minimum_budget: number;
  no_of_owners: string;
  region: string;
  expired: boolean;
  vehicle_category: string;
  vehicle_type: string;
  finance_availability: string;
  engine_warranty: string;
  emission: string;
  params_approval_status :string
}
