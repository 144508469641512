import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatInputModule,
  MatIconModule,
  MatCardModule,
  MatMenuModule,
  MatButtonModule,
  MatChipsModule,
  MatListModule,
  MatTooltipModule,
  MatDialogModule,
  MatSnackBarModule,
  MatSlideToggleModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatProgressBarModule,
  MatRadioModule,
  MatCheckboxModule,
  MatStepperModule,
  MatSelectModule, MatGridListModule,
  MatExpansionModule,
  MatTabsModule,
  MatTableModule, MatFormFieldModule
} from '@angular/material';
import { TranslateLoader } from '@ngx-translate/core';
import { AgmCoreModule } from '@agm/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
//import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { PagesRoutingModule } from './pages-routing.module';
//import { InventoryMasterComponent } from '../../views/pages/inventory-master/inventory-master.component';
import { InventoryListComponent } from '../../views/pages/inventory-list/inventory-list.component';
import { QuillModule } from 'ngx-quill';
import { FileUploadModule } from 'ng2-file-upload/ng2-file-upload';
import { AuctionMasterComponent } from './auction-master/auction-master.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { AuctionListComponent } from './auction-list/auction-list.component';

import { ChartsModule } from 'ng2-charts';
import { NgxEchartsModule } from 'ngx-echarts';

import { SharedPipesModule } from 'app/shared/pipes/shared-pipes.module';
import { DatePipe } from '@angular/common';
import { SharedDirectivesModule } from '../../shared/directives/shared-directives.module';
import { ImagePopupComponent } from './image-popup/image-popup.component';
import { UserListComponent } from './user-list/user-list.component';
import { FileUploadsModule } from 'app/file-upload/file-uploads.module';
import { UserRegistrationComponent } from './user-registration/user-registration.component';
import { AdminRegistrationComponent } from './admin-registration/admin-registration.component';
import { AdminRegistrationListComponent } from './admin-registration-list/admin-registration-list.component';
import { BuyerRegistrationListComponent } from './buyer-registration-list/buyer-registration-list.component';
import { BuyerRegistrationComponent } from './buyer-registration/buyer-registration.component';
import { DepositListComponent } from './deposit-list/deposit-list.component';
import { DepositMasterComponent } from './deposit-master/deposit-master.component';
import { BidListComponent } from './bid-list/bid-list.component';
import { InvListComponent } from './inv-list/inv-list.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { AddInventoryComponent } from './add-inventory/add-inventory.component';
import { BidInventoryComponent } from './bid-inventory/bid-inventory.component';

import { BidderComponent } from './bidder/bidder.component';
import { ClosedBidListComponent } from './closed-bid-list/closed-bid-list.component';
import { BidSettelmentComponent } from './bid-settelment/bid-settelment.component';
import { SettelmentmasterComponent } from './settelmentmaster/settelmentmaster.component';
import { TataServiceModule } from 'app/TataService/tata-service/tata-service.module';
import { InventoryMastersComponent } from './inventory-masters/inventory-masters.component';
import { TestPageComponent } from './test-page/test-page.component';
import { InventoryAddComponent } from './inventory-add/inventory-add.component';
import { RefundListComponent } from './refund-list/refund-list.component';
import { RefundMasterComponent } from './refund-master/refund-master.component';
import { AutionTestComponent } from './aution-test/aution-test.component';
import { BankListComponent } from './bank-list/bank-list.component';
import { BankDetailComponent } from './bank-detail/bank-detail.component';
import { AddAuctionComponent } from './inv-list/add-auction/add-auction.component';
import { CheckStockVehicleComponent } from './inventory-add/chcek-stcok-vehicle/check-stock-vehicle.component';
import { LogUotConfirmation } from 'app/shared/services/app-confirm/LogOutConfirmation';
import { ViewEnquiryComponent } from './view-enquiry/view-enquiry.component';
import { ViewVehicleFilterComponent } from './view-enquiry/view-vehicle-filter/view-vehicle-filter.component';

import { MatSliderModule, MatSidenavModule, MatToolbarModule } from '@angular/material';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { GestureConfig } from '@angular/material';
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';
import { HttpLoaderFactory } from 'app/app.module';
import { HttpClient } from '@angular/common/http';
import { RolePageMappingComponent } from './Master/role-page-mapping/role-page-mapping.component';
import { PositionMasterComponent } from './Master/position-master/position-master.component';
import { ViewEnquiryPopupComponent } from './view-enquiry/view-enquiry-popup/view-enquiry-popup.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { ProductListComponent } from './product-list/product-list.component';
import { AddProductComponent } from './product-list/add-product/add-product.component';
import { PermissionListComponent } from './permission-list/permission-list.component';
import { ProductFilterComponent } from './product-list/product-filter/product-filter.component';
import { DealerInfoComponent } from './product-list/dealer-info/dealer-info.component';
import { LeadListComponent } from './product-list/lead-list/lead-list.component';
import { TsmProductListComponent } from './product-list/tsm-product-list/tsm-product-list.component';
import { InventoryAddRemarkComponent } from './inventory-add-remark/inventory-add-remark.component';



@NgModule({
  providers: [DatePipe,
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },],
  declarations: [InventoryListComponent, AuctionMasterComponent, DashboardComponent, AuctionListComponent,
    ImagePopupComponent, UserListComponent, UserRegistrationComponent, AdminRegistrationComponent,
    AdminRegistrationListComponent, BuyerRegistrationListComponent, BuyerRegistrationComponent,
    DepositListComponent, DepositMasterComponent, BidListComponent, InvListComponent,
    AddInventoryComponent, BidInventoryComponent, BidderComponent, ClosedBidListComponent, InventoryAddComponent,
    BidSettelmentComponent, SettelmentmasterComponent, InventoryMastersComponent, TestPageComponent,
    RefundListComponent, RefundMasterComponent, AutionTestComponent, LogUotConfirmation,
    BankListComponent, BankDetailComponent, AddAuctionComponent, CheckStockVehicleComponent, ViewEnquiryComponent, ViewVehicleFilterComponent,
    RolePageMappingComponent, PositionMasterComponent, ViewEnquiryPopupComponent, ErrorPageComponent, ProductListComponent, AddProductComponent, PermissionListComponent, ProductFilterComponent, DealerInfoComponent, LeadListComponent, TsmProductListComponent, InventoryAddRemarkComponent],
  entryComponents: [AddAuctionComponent, LogUotConfirmation, ViewEnquiryPopupComponent,AddProductComponent,PermissionListComponent,ProductFilterComponent,DealerInfoComponent],
  imports: [NgxPaginationModule, MatFormFieldModule, TataServiceModule,
    CommonModule, FormsModule, ChartsModule, NgxEchartsModule, SharedPipesModule, SharedDirectivesModule,
    ReactiveFormsModule, FileUploadsModule, FlexLayoutModule, NgxDatatableModule, MatInputModule, MatIconModule, MatToolbarModule,
    // BrowserAnimationsModule,
    MatCardModule, MatMenuModule, MatSliderModule, MatButtonModule, MatSidenavModule, MatChipsModule, MatListModule,
    MatTooltipModule, MatDialogModule, MatAutocompleteModule, MatSnackBarModule, MatSlideToggleModule, TranslateModule,
    // SharedModule,
    PerfectScrollbarModule, MatDatepickerModule, MatNativeDateModule, MatProgressBarModule, MatRadioModule, MatCheckboxModule,
    MatSelectModule, MatStepperModule, QuillModule, NgxDatatableModule, FileUploadModule, OwlDateTimeModule,
    OwlNativeDateTimeModule, MatGridListModule, MatExpansionModule, MatTabsModule, MatTableModule,
    //RouterModule.forChild(PagesRoutingModule)
    PagesRoutingModule,
    AgmCoreModule.forRoot({ apiKey: 'AIzaSyBcGudZMXCMmd9uHo-WXYrBGplcWvCNOZU' }),

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ]
})
export class PagesModule { }
